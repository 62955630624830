<template>
  <div>
    <b-card>
      <b-card-title class="mb-0">เอกสารยืนยันตัวตน</b-card-title>
      <hr class="mt-50" />
      <validation-observer ref="formPartnerCreditInfo">
        <b-form>
          <b-row cols="1">
            <b-col>
              <b-form-group label="ชื่อร้านค้า" label-for="f_shop_name">
                <validation-provider #default="{ errors }" name="ชื่อร้านค้า" rules="required|min:6">
                  <b-form-input
                    id="f_shop_name"
                    v-model="form.shop_name"
                    name="f_shop_name"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุชื่อร้านค้า"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="เลือก ธนาคาร" label-for="f_bank_type">
                <validation-provider #default="{ errors }" name="ธนาคาร" rules="required">
                  <v-select
                    id="f_bank_type"
                    v-model="form.bank_type"
                    name="f_bank_type"
                    :options="options.banks"
                    :clearable="false"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="สาขา" label-for="f_major">
                <validation-provider #default="{ errors }" name="สาขา" rules="required">
                  <b-form-input
                    id="f_major"
                    v-model="form.major"
                    name="f_major"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุสาขาธนาคาร"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="เลขบัญชี" label-for="f_bank_no">
                <validation-provider #default="{ errors }" name="เลขบัญชี" rules="required">
                  <b-form-input
                    id="f_bank_no"
                    v-model="form.bank_no"
                    name="f_bank_no"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุเลขบัญชี"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col>
              <b-form-group label="ชื่อ-นามสกุล" label-for="f_account">
                <validation-provider #default="{ errors }" name="ชื่อ-นามสกุล" rules="required|min:6">
                  <b-form-input
                    id="f_account"
                    v-model="form.account"
                    name="f_account"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุชื่อ-นามสกุล"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group label="เลขบัตรประชาชน" label-for="f_pid">
                <validation-provider #default="{ errors }" name="เลขบัตรประชาชน" rules="required|alpha-num|length:13">
                  <b-form-input
                    id="f_pid"
                    v-model="form.pid"
                    name="f_pid"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="เลขบัตรประชาชน"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="ชื่อ" label-for="f_first_name">
                <validation-provider #default="{ errors }" name="ชื่อ" rules="required|min:2">
                  <b-form-input
                    id="f_first_name"
                    v-model="cFirstName"
                    name="f_first_name"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุชื่อ"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="นามสกุล" label-for="f_last_name">
                <validation-provider #default="{ errors }" name="นามสกุล" rules="required|min:2">
                  <b-form-input
                    id="f_last_name"
                    v-model="cLastName"
                    name="f_last_name"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุนามสกุล"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="ที่อยู่" label-for="f_address.address">
                <validation-provider #default="{ errors }" name="ที่อยู่" rules="required">
                  <b-form-input
                    id="f_address.address"
                    v-model="form.address"
                    name="f_address.address"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุที่อยู่"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <div class="mb-1">
                <validation-provider #default="{ errors }" name="ตำบล/แขวง" rules="required">
                  <addressinput-subdistrict
                    v-model="form.address_json.subdistrict"
                    :input-class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="โปรดระบุ"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-1">
                <validation-provider #default="{ errors }" name="อำเภอ/เขต" rules="required">
                  <addressinput-district
                    v-model="form.address_json.district"
                    :input-class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="โปรดระบุ"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-1">
                <validation-provider #default="{ errors }" name="จังหวัด" rules="required">
                  <addressinput-province
                    v-model="form.address_json.province"
                    :input-class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="โปรดระบุ"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-1">
                <validation-provider #default="{ errors }" name="รหัสไปรษณีย์" rules="required">
                  <addressinput-zipcode
                    v-model="form.address_json.zipcode"
                    :input-class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    placeholder="โปรดระบุ"
                    :disabled="!canEditOrUpdate"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col>
              <b-form-group label="เบอร์โทรศัพท์" label-for="f_phone_number">
                <validation-provider #default="{ errors }" name="เบอร์โทรศัพท์" rules="required|numeric|min:9|max:10">
                  <b-form-input
                    id="f_phone_number"
                    v-model="form.phone_number"
                    name="f_phone_number"
                    :state="errors.length > 0 ? false : null"
                    type="text"
                    placeholder="ระบุเบอร์โทรศัพท์"
                    :disabled="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="หน้าสมุดธนาคารของท่าน (พร้อมเซ็นกำกับ)" label-for="f_image_bank_info">
                <upload-image-widget
                  v-if="canEditOrUpdate"
                  @uploadFile="fileObj => onUpload(fileObj, 'image_bank_info')"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <div v-if="form.image_bank_info" class="mb-1 d-flex justify-content-center">
                <div class="preview_image">
                  <b-img
                    :src="form.image_bank_info.blobUrl || form.image_bank_info"
                    height="200"
                    width="auto"
                    @click="gZoomImage"
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <b-form-group label="สำเนาบัตรประชาชน (พร้อมเซ็นกำกับ)" label-for="f_image_card_info">
                <upload-image-widget
                  v-if="canEditOrUpdate"
                  @uploadFile="fileObj => onUpload(fileObj, 'image_card_info')"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <div v-if="form.image_card_info" class="mb-1 d-flex justify-content-center">
                <div class="preview_image">
                  <b-img
                    :src="form.image_card_info.blobUrl || form.image_card_info"
                    height="200"
                    width="auto"
                    @click="gZoomImage"
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <b-form-group label="รูปคู่บัตรประชาชน" label-for="f_image_card_and_user_info">
                <div class="d-flex align-items-center flex-column flex-sm-row">
                  <img :src="require('@/assets/images/ongphra/ex_pid_person_image.png')" alt="" style="width: 100px" />
                  <upload-image-widget
                    v-if="canEditOrUpdate"
                    @uploadFile="fileObj => onUpload(fileObj, 'image_card_and_user_info')"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col>
              <div v-if="form.image_card_and_user_info" class="mb-1 d-flex justify-content-center">
                <div class="preview_image">
                  <b-img
                    :src="form.image_card_and_user_info.blobUrl || form.image_card_and_user_info"
                    height="200"
                    width="auto"
                    @click="gZoomImage"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
          <b-button
            v-if="canEditOrUpdate"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            @click="submitPartnerCreditInfo"
          >
            ยื่นเอกสารยืนยันตัวตน
          </b-button>
        </b-form>
      </validation-observer>
      <!-- <pre>{{ form }}</pre> -->
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric, double } from '@validations'
import vSelect from 'vue-select'
import mediumZoom from 'medium-zoom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { DataStore } from 'vue-thailand-address'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {
    canEditOrUpdate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        id: '',
        shop_name: '',
        account: '', // ชื่อของ partner
        major: '', // สาขา bank
        bank_no: '',
        bank_type: 'ธนาคารกรุงเทพ',
        image_bank_info: null,
        image_card_info: null,
        image_card_and_user_info: null,
        phone_number: '',
        address: '',
        address_json: {
          subdistrict: '',
          district: '',
          province: '',
          zipcode: '',
        },
        pid: '',
      },
      addressStore: new DataStore(),
      options: {
        banks: [
          'ธนาคารกรุงเทพ',
          'ธนาคารกรุงไทย',
          'ธนาคารกรุงศรีอยุธยา',
          'ธนาคารกสิกรไทย',
          'ธนาคารเกียรตินาคินภัทร',
          'ธนาคารซีไอเอ็มบี',
          'ธนาคารทหารไทยธนชาต',
          'ธนาคารทิสโก้',
          'ธนาคารไทยพาณิชย์',
          'ธนาคารยูโอบี',
          'ธนาคารแลนด์',
          'ธนาคารสแตนดาร์ดชาร์เตอร์ด',
          'ธนาคารไอซีบีซี',
        ],
      },
    }
  },
  computed: {
    cFirstName: {
      get() {
        return `${this.form.account}`.split(' ')[0] ?? ''
      },
      set(newValue) {
        // console.log('cFirstName set', newValue)
        const last = `${this.form.account}`.split(' ')[1] ?? ''

        this.form.account = `${newValue} ${last}`
      },
    },
    cLastName: {
      get() {
        return `${this.form.account}`.split(' ')[1] ?? ''
      },
      set(newValue) {
        // console.log('cLastName set', newValue)
        const first = `${this.form.account}`.split(' ')[0] ?? ''

        this.form.account = `${first} ${newValue}`
      },
    },
  },
  methods: {
    onUpload(fileObj, formKey) {
      // console.log('onUpload', { fileObj, formKey })
      if (Number(fileObj.file.fileSizeMB) > 2) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'รูปต้องมีขนาดไม่เกิน 2 MB',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      this.form[formKey] = fileObj
    },
    zoomImage(ev) {
      const zoom = mediumZoom({ background: '#191f32', margin: 48 })
      zoom.attach(ev.target)
      zoom.on('closed', () => zoom.detach(), { once: true })
    },
    async submitPartnerCreditInfo() {
      if (!this.canEditOrUpdate) return
      const valid = await this.$refs.formPartnerCreditInfo.validate()

      if (!valid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดระบุข้อมูลพร้อมรูปให้ครบถ้วน',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }
      const { image_bank_info, image_card_info, image_card_and_user_info } = this.form
      // eslint-disable-next-line camelcase
      if (!image_bank_info || !image_card_info || !image_card_and_user_info) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'โปรดอัพโหลดรูปให้ครบถ้วน',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.$emit('submit', this.form)
    },
    updateForm(form) {
      this.form = { ...form, bank_type: form.bank_type || this.options.banks[0], pid: form?.pid ?? '' }
    },
  },
}
</script>

<style lang="scss" scoped>
.preview_image {
  cursor: zoom-in;
}
</style>
