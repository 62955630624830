<template>
  <b-card>
    <b-card-title>ขั้นตอนในการตั้งค่าบัญชี</b-card-title>
    <app-timeline>
      <app-timeline-item
        title="กรอกข้อมูลเอกสารยืนยันตัวตน"
        subtitle="หลังจากกรอกเอกสารครบถ้วนแล้วให้กด ยื่นเอกสารยืนยันตัวตน เพื่อให้ผู้ดูแลระบบทำการตรวจสอบ"
        :icon="statusTimeline1().icon"
        :variant="statusTimeline1().variant"
      />

      <app-timeline-item :variant="statusVariant('settingInfo')" :icon="statusIcon('settingInfo')">
        <h6>
          ตรวจสอบเอกสารยืนยันตัวตน
        </h6>
        <p class="mb-50">สถานะ : {{ statusTextFormat('settingInfo') }}</p>
        <p v-if="verifySettingInfo">หมายเหตุ : {{ verifySettingInfo.note || '-' }}</p>
      </app-timeline-item>

      <app-timeline-item :icon="cStatusIconStep3" :variant="cStatusVariantStep3">
        <h6>เซ็นเอกสารสัญญา</h6>
        <p>
          โปรดอ่านเอกสารสัญญา หลักจากนั้นให้เซ็นลายเซ็นที่ช่อง "ลงลายมือชื่อเพื่อยอมรับเอกสารสัญญาแบบ digital"
          เพื่อดำเนินการต่อ หลังจากเซ็นเรียบร้อยแล้ว จะถูกส่งไปให้แอดมินตรวจสอบอีกครั้ง
        </p>
      </app-timeline-item>

      <app-timeline-item :variant="statusVariant('agreementInfo')" :icon="statusIcon('agreementInfo')">
        <h6>
          ตรวจสอบเอกสารสัญญา
        </h6>
        <div v-if="verifyAgreementInfo">
          <p class="mb-50">สถานะ : {{ statusTextFormat('agreementInfo') }}</p>
          <p>หมายเหตุ : {{ verifyAgreementInfo.note }}</p>
          <!-- <p v-if="verifyAgreementInfo.status === 'Pending'">
            ผู้ดูแลระบบจะทำการส่งเอกสารสัญญาไปยังที่อยู่จากเอกสารยืนยันตัวตน
            หลักจากได้รับแล้วโปรดทำการตรวจสอบและเซ็นสัญญาทั้ง 2 ฉบับ และส่งกลับมา 1
            ฉบับพร้อมสำเนาบัตรประชาชนและหน้าสมุดธนาคารของท่าน (พร้อมเซ็นกำกับ) และรอการตรวจสอบอีกกครั้ง
          </p> -->
          <!-- <p v-if="verifyAgreementInfo.status === 'Pending document contract'">
            รอการตรวจสอบเอกสารสัญญาจากทางแอดมิน
          </p> -->
        </div>
      </app-timeline-item>
    </app-timeline>

    <!-- <pre>verifySettingInfo {{ verifySettingInfo }}</pre>
    <pre>verifyAgreementInfo {{ verifyAgreementInfo }}</pre> -->
  </b-card>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    verifySettingInfo: {
      type: Object,
      default: () => null,
    },
    verifyAgreementInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      icons: ['CheckCircleIcon', 'ClockIcon', 'AlertIcon'],
    }
  },
  computed: {
    statusVariant() {
      const format = {
        'Wait Document': 'secondary',
        Approval: 'success',
        Reject: 'danger',
      }

      return key => {
        if (key === 'settingInfo') return format[this.verifySettingInfo?.status] ?? 'secondary'

        if (key === 'agreementInfo') return format[this.verifyAgreementInfo?.status] ?? 'secondary'
        return 'secondary'
      }
    },
    statusIcon() {
      const format = {
        'Wait Document': 'ClockIcon',
        Approval: 'CheckCircleIcon',
        Reject: 'AlertCircleIcon',
      }

      return key => {
        if (key === 'settingInfo') return format[this.verifySettingInfo?.status] ?? 'ClockIcon'

        if (key === 'agreementInfo') return format[this.verifyAgreementInfo?.status] ?? 'ClockIcon'

        return 'ClockIcon'
      }
    },
    statusTextFormat() {
      const format = {
        'Wait Document': 'รอส่งเอกสารยืนยันตัวตน',
        Submit: 'รอตรวจสอบเอกสารยืนยันตัวตน',
        Approval: 'ตรวจสอบสำเร็จ',
        Reject: 'ตรวจสอบไม่สำเร็จ',
        Pending: 'รอการตรวจสอบ',
        'Create document contract': 'รอเซ็นเอกสารสัญญา',
        'Pending document contract': 'รอตรวจสอบเอกสารสัญญาจากแอดมิน',
      }

      return key => {
        if (key === 'settingInfo') return format[this.verifySettingInfo?.status] ?? ''

        if (key === 'agreementInfo') return format[this.verifyAgreementInfo?.status] ?? ''

        return ''
      }
    },
    cStatusVariantStep3() {
      const status = this.verifyAgreementInfo?.status

      if (status === 'Approval') return 'success'

      if (status === 'Pending document contract') return 'success'

      return 'secondary'
    },
    cStatusIconStep3() {
      const status = this.verifyAgreementInfo?.status

      if (status === 'Approval') return 'CheckCircleIcon'

      if (status === 'Pending document contract') return 'CheckCircleIcon'

      return 'ClockIcon'
    },
  },
  methods: {
    statusTimeline1() {
      let variant = 'secondary'
      let icon = 'ClockIcon'

      if (this.verifySettingInfo?.status !== 'Wait Document') {
        variant = 'success'
        icon = 'CheckCircleIcon'
      }

      return { variant, icon }
    },
  },
}
</script>

<style lang="scss" scoped></style>
