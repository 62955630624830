<template>
  <div>
    <h1 class="mb-2">ตั้งค่าบัญชี</h1>
    <!-- <b-alert variant="warning" show>
      <div class="alert-body">
        <span>โปรดทำการตั้งค่าบัญชีเพื่อรับสิทธิในการเข้าใช้งานหลังบ้านของ Ongphra Wallpaper</span>
      </div>
    </b-alert> -->
    <!-- <b-button @click="test">test</b-button> -->
    <b-row>
      <b-col sm="12" md="7" order="2" order-md="1">
        <b-overlay :show="loading">
          <card-partner-form-setting
            ref="refFormSetting"
            @submit="submitFormPartner"
            :canEditOrUpdate="cCanEditOrUpdate"
          />
          <!-- <pre>{{ partnerSettingInfo }}</pre> -->
        </b-overlay>
      </b-col>
      <b-col sm="12" md="5" order="1" order-md="2">
        <b-overlay :show="loading">
          <card-partner-timeline-setting
            :verifySettingInfo="partnerSettingInfo.verifySettingInfo"
            :verifyAgreementInfo="partnerSettingInfo.verifyAgreementInfo"
          />
        </b-overlay>
      </b-col>
      <b-col v-if="cCanShowFormAgreementInfo" sm="12" md="7" order="3">
        <b-overlay :show="loading">
          <card-partner-form-agreement
            :form="partnerSettingInfo.form"
            :verify-agreement-info="partnerSettingInfo.verifyAgreementInfo"
            :verify-setting-info="partnerSettingInfo.verifySettingInfo"
            @onSuccess="onSubmitFormAgreementSuccess"
          />
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CardPartnerTimelineSetting from './components/CardPartnerTimelineSetting.vue'
import CardPartnerFormSetting from './components/CardPartnerFormSetting.vue'
import CardPartnerFormAgreement from './components/CardPartnerFormAgreement.vue'

export default {
  components: {
    CardPartnerTimelineSetting,
    CardPartnerFormSetting,
    CardPartnerFormAgreement,
  },
  data() {
    return {
      partnerSettingInfo: {
        form: null,
        verifySettingInfo: null,
        verifyAgreementInfo: null,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters('AuthStore', ['_userInfo']),
    cCanEditOrUpdate() {
      const { verifySettingInfo } = this.partnerSettingInfo

      if (verifySettingInfo?.status === 'Wait Document' || verifySettingInfo?.status === 'Reject') {
        return true
      }
      return false
    },
    cCanShowFormAgreementInfo() {
      const { verifyAgreementInfo, verifySettingInfo } = this.partnerSettingInfo

      // if (
      //   verifyAgreementInfo?.status === 'Create document contract' ||
      //   verifyAgreementInfo?.status === 'Reject' ||
      //   verifyAgreementInfo?.status === 'Rejected' ||
      //   verifyAgreementInfo?.status === 'Approve' ||
      //   verifyAgreementInfo?.status === 'Approval' ||
      //   verifyAgreementInfo?.status === 'Pending document contract'
      // ) {
      //   return true
      // }

      // if (verifySettingInfo?.status === 'Submit') return false

      if (verifyAgreementInfo?.status !== 'Pending') return true

      return false
    },
  },
  async created() {
    this.loading = true
    await this.getPartnerSettingInfo()
    this.loading = false
  },
  methods: {
    ...mapActions('AuthStore', ['getUserInfo']),
    async getPartnerSettingInfo() {
      // this.partnerSettingInfo.verifySettingInfo = { status: 'Wait Document', note: '' }
      // this.partnerSettingInfo.verifyAgreementInfo = { status: 'pending', note: '' }
      // return
      const resp = await this.api.get('api/partner/verifier').catch(() => null)
      // console.log('getPartnerSettingInfo', resp)
      if (resp) {
        const { verify_agreement_info, verify_setting_info, ...props } = resp
        this.partnerSettingInfo = {
          form: { ...props },
          verifySettingInfo: verify_setting_info,
          verifyAgreementInfo: verify_agreement_info,
        }

        this.$refs.refFormSetting.updateForm({ ...props })
        if (verify_agreement_info.status === 'Approval' && verify_setting_info.status === 'Approval') {
          // eslint-disable-next-line no-underscore-dangle
          await this.getUserInfo()
        }
      }
    },
    async submitFormPartner(form) {
      this.loading = true
      const formData = new FormData()
      Object.keys(form).forEach(k => {
        let value = form[k]
        if (k.includes('image')) {
          value = value.file ?? value
        } else if (k === 'address_json') {
          value = Buffer.from(JSON.stringify(value)).toString('base64')
        }
        formData.append(k, value)
      })
      const resp = await this.api.post('api/partner/verifier', formData).catch(() => null)
      // console.log('submitFormPartner', resp)
      if (resp) {
        // await this.getPartnerSettingInfo()
        // this.partnerSettingInfo.verifySettingInfo = { status: 'Submit', note: '' }
        // this.partnerSettingInfo.verifyAgreementInfo = { status: 'Pending', note: '' }
        await this.getPartnerSettingInfo()
        this.gDisplayToast('ยื่นเอกสารสำเร็จ')
      } else {
        this.gDisplayToast('ยื่นเอกสารไม่สำเร็จ', '', 'danger')
      }

      this.loading = false
    },
    async onSubmitFormAgreementSuccess() {
      this.loading = true
      await this.getPartnerSettingInfo()
      this.loading = false
    },
    async test() {
      this.$ability.update([
        { action: 'manage', subject: 'partner' },
        { action: 'manage', subject: 'partner-dashboard' },
        { action: 'manage', subject: 'partner-setting' },
        { action: 'manage', subject: 'Auth' },
      ])
    },
  },
}
</script>

<style lang="scss" scoped></style>
