<template>
  <div>
    <b-card>
      <b-card-title class="mb-0">เอกสารสัญญา</b-card-title>
      <hr class="mt-50" />
      <p>
        {{ textHintDetails }}
      </p>
      <a class="mb-0 d-flex align-items-center justify-content-center my-2" :href="cPdfLink" target="_blank">
        <b-img :src="require('@/assets/images/icons/pdf.png')" height="auto" width="40" class="mr-1" />
        <span class="align-bottom">อ่านเอกสารสัญญา</span>
      </a>
      <button-widget
        v-if="cShowFormSignature"
        text="เซ็นเอกสารสัญญา"
        @click="openModalSignature"
        block
        class="mt-1"
        variant="primary"
      />
      <!-- <div v-show="cShowFormSignature">
        <VueSignaturePad
          id="signaturePad"
          ref="refSignaturePad"
          width="100%"
          height="200px"
          :options="optionsSignaturePad"
        />

        <button-widget
          text="ล้างลายมือชื่อ"
          block
          class="mt-1"
          variant=""
          @click="$refs.refSignaturePad.clearSignature()"
        />
        <button-widget text="ยอมรับเอกสารสัญญา" @click="onClickConfirmAgreeInfo" block class="mt-1" variant="success" />
      </div> -->
      <canvas ref="refCanvasTemp" width="952" height="248" class="d-none"></canvas>
    </b-card>

    <b-modal
      ref="refModalSignature"
      title="ลงลายมือชื่อเซ็นเอกสารสัญญา"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      centered
    >
      <div style="position: relative">
        <VueSignaturePad
          id="signaturePad"
          ref="refSignaturePad"
          width="100%"
          height="200px"
          :options="optionsSignaturePad"
        />
        <p v-if="showPlaceholder" class="custom-placeholder-signature-pad">ลงลายมือชื่อที่ช่องนี้</p>
      </div>

      <button-widget text="ล้างลายมือชื่อ" block class="mt-1" variant="danger" @click="resetSignature" />
      <button-widget
        text="ยอมรับเอกสารสัญญา"
        :disabled="showPlaceholder"
        class="mt-50"
        block
        variant="success"
        @click="onClickConfirmAgreeInfo"
      />
    </b-modal>

    <modal-otp-widget
      ref="refModalOTPSignature"
      :phone-number="modelPhoneNumber"
      :ref-code="refOtp"
      :loading="false"
      input-type="text"
      @confirm="sandOtpVerify"
    />
  </div>
</template>

<script>
import { VueSignaturePad } from 'vue-signature-pad'

export default {
  components: {
    VueSignaturePad,
  },
  props: {
    verifySettingInfo: {
      type: Object,
      default: () => null,
    },
    verifyAgreementInfo: {
      type: Object,
      default: () => null,
    },
    form: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      optionsSignaturePad: {
        penColor: '#000000',
        onBegin: this.onPadBegin,
      },
      fileSignature: null,
      refOtp: null,
      serverRef: null,
      showPlaceholder: true,
    }
  },
  computed: {
    cPdfLink() {
      return this.form?.contract_document_url
    },
    modelPhoneNumber() {
      return this.form?.phone_number
    },
    cShowFormSignature() {
      if (
        this?.verifyAgreementInfo?.status === 'Pending document contract' ||
        this?.verifySettingInfo?.status === 'Submit' ||
        this?.verifyAgreementInfo?.status === 'Approval'
      ) {
        return false
      }

      return true
    },
    textHintDetails() {
      if (this?.verifyAgreementInfo?.status === 'Approval') return 'เอกสารสัญญาญาได้รับการอนุมัติเรียบร้อยแล้ว'

      return 'ลงลายมือชื่อเพื่อยอมรับเอกสารสัญญาแบบ digital'
    },
  },
  mounted() {
    window.addEventListener('resize', this.resetSignature)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resetSignature)
  },
  methods: {
    async onClickConfirmAgreeInfo() {
      const { isEmpty, data } = this.$refs.refSignaturePad.saveSignature('image/svg+xml')

      if (isEmpty) {
        this.gDisplayToast('โปรดลงลายมือชื่อ', '', 'danger')
        return
      }
      this.gOpenPageLoading()
      this.refOtp = null
      this.serverRef = null

      const url = await this.fromDataURL(data)

      // const link = document.createElement('a')
      // link.download = 'signature.png'
      // link.href = url
      // document.body.appendChild(link)
      // link.click()
      // document.body.removeChild(link)
      const file = await this.dataUrlToFile(url)

      const resp = await this.api.postV2(
        'api/partner/contract-document-otp',
        {
          document_data_id: this.form.document_data_id,
        },
        this,
      )
      this.gClosePageLoading()

      if (resp?.code === 200) {
        this.fileSignature = file
        this.refOtp = resp.data['show-ref']
        this.serverRef = resp.data['server-ref']
        this.$refs.refModalOTPSignature.show()
        return
      }
      if (resp?.code === 405) {
        this.gDisplayToast('พบข้อผิดพลาดบางอย่าง', resp?.data?.message, 'danger')
        return
      }

      this.gDisplayToast('พบข้อผิดพลาดบางอย่าง', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
    },
    async sandOtpVerify(otp) {
      if (!otp) return

      if (`${otp}`.length !== 6) {
        this.gDisplayToast('พบข้อผิดพลาด', 'รหัส OTP ไม่ถูกต้อง', 'danger')
        return
      }

      const formData = new FormData()
      formData.append('file', this.fileSignature)
      formData.append('token', `${otp}`)
      formData.append('document_data_id', this.form.document_data_id)
      formData.append('server_ref', this.serverRef)
      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/partner/contract-document-verify', formData, this)
      this.gClosePageLoading()

      if (resp?.code === 200) {
        this.$refs?.refModalOTPSignature?.hide()
        this.$refs?.refModalSignature?.hide()
        this.gDisplayToast('เซ็นเอกสารสัญญาเรียบร้อยแล้ว', 'โปรดรอการตรวจสอบจากแอดมิน')
        this.$emit('onSuccess')
      } else {
        this.gDisplayToast('พบข้อผิดพลาดบางอย่าง', resp?.data?.message, 'danger')
        //
      }
    },
    fromDataURL(data) {
      return new Promise(resolve => {
        const image = new Image()

        image.onload = () => {
          const canvas = this.$refs.refCanvasTemp
          const ctx = canvas.getContext('2d')
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          // eslint-disable-next-line no-unused-expressions
          ctx?.drawImage(image, 0, 0, canvas.width, canvas.height)
          resolve(canvas.toDataURL('image/png'))
        }

        image.crossOrigin = 'anonymous'
        image.src = data
      })
    },
    async dataUrlToFile(data) {
      const resp = await fetch(data)
      const b = await resp.blob()
      return new File([b], 'signature.png', { type: 'image/png' })
    },
    async resetSignature() {
      this.$refs?.refSignaturePad?.clearSignature()
      this.showPlaceholder = true
    },
    openModalSignature() {
      this.showPlaceholder = true
      this.fileSignature = null
      this.$refs.refModalSignature.show()
      setTimeout(() => {
        this.$refs?.refSignaturePad?.resizeCanvas()
      }, 250)
    },
    onPadBegin() {
      this.showPlaceholder = false
    },
  },
}
</script>

<style lang="scss" scoped>
#signaturePad {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #313031);
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;
}

.custom-placeholder-signature-pad {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  opacity: 0.5;
}
</style>
